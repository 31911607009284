export enum Role {
    SuperAdmin = 'SuperAdmin',
    Admin = 'Admin',
    BranchAdmin = 'BranchAdmin',
    OutletAdmin = 'OutletAdmin',
    Finance = 'Finance',
    Marketing = 'Marketing',
    Basic = 'Basic',
    BillingAndCollection = 'BillingAndCollection'
  }
  