import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "home",
    role: ["Basic"],
  },
  // {
  //   id: 'purchases',
  //   title: 'Purchases',
  //   translate: 'MENU.PURCHASES',
  //   type: 'item',
  //   icon: 'shopping-bag',
  //   url: 'purchases',
  //   role: ['Basic']
  // },
  {
    id: "shareCapital",
    title: "Share Capital",
    translate: "MENU.SHARE_CAPITAL",
    type: "item",
    icon: "dollar-sign",
    url: "share-capital",
    role: ["Basic"],
  },
  {
    id: 'investments',
    title: 'Investments',
    translate: 'MENU.INVESTMENTS',
    type: 'item',
    icon: 'trending-up',
    url: 'investments',
    role: ['Basic']
  },
  {
    id: "accountManagement",
    title: "Account Mgmt.",
    translate: "MENU.SETUP",
    type: "collapsible",
    icon: 'book-open',
    role: ["Basic"],
    children: [
      {
        id: "accountReceivable",
        title: "Account Receivable",
        type: "collapsible",
        children: [
          {
            id: "ar-Mdse",
            title: "AR-Mdse",
            type: "item",
            url: "accountmanagement/accountreceivable/ar-merchandise",
          },
          {
            id: "ar-Hogs",
            title: "AR-Hogs",
            type: "item",
            url: "accountmanagement/accountreceivable/ar-hogs",
          },
          {
            id: "ar-Aqua",
            title: "AR-Aqua",
            type: "item",
            url: "accountmanagement/accountreceivable/ar-aqua",
          },
          {
            id: "ar-SmatV",
            title: "AR-SmatV",
            type: "item",
            url: "accountmanagement/accountreceivable/ar-smatv",
          },
          {
            id: "ar-Fiber",
            title: "AR-Fiber",
            type: "item",
            url: "accountmanagement/accountreceivable/ar-fiber",
          },
        ],
        
      },
      // {
      //   id: "loansReceivable",
      //   title: "Loan Receivable",
      //   type: "item",
      //   url: "accountmanagement/loanreceivable",
      // },
    ],
  },
  // {
  //   id: 'transactions',
  //   title: 'Transactions',
  //   translate: 'MENU.MEMBER_TRANSACTIONS',
  //   type: 'item',
  //   icon: 'shopping-bag',
  //   url: 'transactions',
  //   role: ['Basic']
  // },
  // {
  //   id: 'loans',
  //   title: 'Loans',
  //   translate: 'MENU.LOANS',
  //   type: 'item',
  //   icon: 'credit-card',
  //   url: 'loans',
  //   role: ['Basic']
  // },

  {
    id: 'updateRequest',
    title: 'Update Request',
    translate: 'MENU.CHANGE_REQUEST',
    type: 'item',
    icon: 'edit',
    url: 'applicant-update-request',
    role: ['Basic']
  },

  {
    id: 'membersReport',
    title: 'Reports',
    translate: 'MENU.CHANGE_REQUEST',
    type: 'item',
    icon: 'book',
    url: 'reports',
    role: ['Basic']
  },
  {
    id: "contactUs",
    title: "Contact Us",
    translate: "MENU.CONTACT_US",
    type: "item",
    icon: "phone",
    url: "contact-us",
    role: ["Basic"],
  },
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD",
    type: "item",
    icon: "home",
    url: "admin/dashboard",
    role: ["SuperAdmin", "Admin", "BranchAdmin", "OutletAdmin","BillingAndCollection"],
  },
  {
    id: "applicants",
    title: "Applicants",
    translate: "MENU.APPLICANTS",
    type: "item",
    icon: "user-plus",
    url: "admin/applicants",
    role: ["SuperAdmin", "Admin", "BranchAdmin", "OutletAdmin", "Finance"],
  },
  {
    id: "members",
    title: "Members",
    translate: "MENU.MEMBERS",
    type: "item",
    icon: "user-check",
    url: "admin/members",
    role: ["SuperAdmin", "Admin", "BranchAdmin", "OutletAdmin"],
  },
  {
    id: "leads",
    title: "Leads",
    translate: "MENU.LEADS",
    type: "item",
    icon: "zoom-in",
    url: "admin/leads",
    role: ["SuperAdmin", "Admin", "BranchAdmin"],
  },
  {
    id: "events",
    title: "Seminars",
    translate: "MENU.EVENTS",
    type: "item",
    icon: "calendar",
    url: "admin/events",
    role: ["SuperAdmin", "Admin", "BranchAdmin"],
  },
  {
    id: "vouchers",
    title: "Vouchers",
    translate: "MENU.VOUCHERS",
    type: "item",
    icon: "gift",
    url: "admin/vouchers",
    role: ["SuperAdmin", "Admin"],
  },
  {
    id: "adminUpdateRequest",
    title: "Update Request",
    translate: 'MENU.CHANGE_REQUEST',
    type: "item",
    icon: 'edit',
    url: "admin/adminchangerequest",
    role: ["SuperAdmin", "Admin"],
  },
  {
    id: "announcements",
    title: "Announcements",
    translate: "MENU.ANNOUNCEMENTS",
    type: "item",
    icon: "bell",
    url: "admin/announcements",
    role: ["SuperAdmin", "Marketing"],
  },
  {
    id: "financials",
    title: "Financials",
    translate: "MENU.FINANCIALS",
    type: "collapsible",
    icon: "layers",
    role: ["SuperAdmin", "BillingAndCollection"],
    children: [
      {
        id: "accountReceivable",
        title: "Account Receivable",
        type: "collapsible",
        children: [
          {
            id: "accountReceivableMerchandise",
            title: "AR-Merchandise",
            type: "item",
            url: "admin/financials/ar-merchandise",
          },
          {
            id: "accountReceivableHogs",
            title: "AR-Hogs",
            type: "item",
            url: "admin/financials/ar-hogs",
          },
          {
            id: "accountReceivableAqua",
            title: "AR-Aqua",
            type: "item",
            url: "admin/financials/ar-aqua",
          },
          {
            id: "accountReceivableSmatV",
            title: "AR-SmatV",
            type: "item",
            url: "admin/financials/ar-smatv",
          },
          {
            id: "accountReceivableFiber",
            title: "AR-Fiber",
            type: "item",
            url: "admin/financials/ar-fiber",
          },
        ]
        
      },
      // {
      //   id: "loansReceivable",
      //   title: "Loans Receivable",
      //   type: "item",
      //   url: "admin/financials/loans-receivable",
      // },
      {
        id: "investment",
        title: "Investment",
        type: "item",
        url: "admin/financials/investment",
      },
    ],
  },
  {
    id: "dividend",
    title: "Dividend",
    translate: "MENU.DIVIDEND",
    type: "item",
    icon: "grid",
    url: "admin/dividend",
    role: ["SuperAdmin"],
  },
  {
    id: "reports",
    title: "Reports",
    translate: "MENU.REPORTS",
    type: "item",
    icon: "book",
    url: "admin/reports",
    role: ["SuperAdmin", "Admin", "BranchAdmin", "Finance"],
  },
  {
    id: "users",
    title: "Users",
    translate: "MENU.USERS",
    type: "item",
    icon: "users",
    url: "admin/users",
    role: ["SuperAdmin"],
  },
  {
    id: "setup",
    title: "Setup",
    translate: "MENU.SETUP",
    type: "collapsible",
    icon: "tool",
    role: ["SuperAdmin", "Admin"],
    children: [
      {
        id: "membershipRequirements",
        title: "Requirements",
        type: "item",
        url: "admin/setup/membership-requirements",
      },
      {
        id: "regionMunicipalities",
        title: "Region",
        type: "item",
        url: "admin/setup/region-municipalities",
      },
    ],
  },
  {
    id: "superAdminSetup",
    title: "Super Admin Setup",
    translate: "MENU.SUPER_ADMIN_SETUP",
    type: "collapsible",
    icon: "settings",
    role: ["SuperAdmin"],
    children: [
      {
        id: "evote",
        title: "Evote",
        type: "item",
        url: "admin/superadminsetup/evote-links",
      },
      {
        id: "application-config",
        title: "Application Config",
        type: "item",
        url: "admin/superadminsetup/application-config",
      },
     
    ],
  },
  // {
  //   id: 'settings',
  //   title: 'Settings',
  //   translate: 'MENU.SETTINGS',
  //   type: 'collapsible',
  //   role: ['Basic', 'Admin'],
  //   icon: 'settings',
  //   children: [
  //   ]
  // },
];
