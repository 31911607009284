import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { coreConfig } from 'app/app-config';
import { Router } from '@angular/router';
import { AuthService } from 'app/common/services/auth.service';
import { User } from 'app/common/models/user';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicantService } from 'app/read-api';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public tokenOnInit: string;
  public currentUserOnInit: string;
  public logoutTriggered: boolean = false;
  public fullName: string;
  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private _applicantService: ApplicantService,
    private _toastr: ToastrService
  ) {
    this._authService.currentUser.subscribe(x => (this.currentUser = x));
    
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      tl: {
        title: 'Tagalog',
        flag: 'ph'
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this.logoutTriggered = true;
    if (!this._authService.isClient) {
      this._authService.clearSession();
      this._router.navigate(['/auth/login/admin']);
    } else {
      this._authService.clearSession();
      this._router.navigate(['/auth/login']);      
      window.location.reload();
    } 
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    this.tokenOnInit = sessionStorage.getItem("token");
    this.currentUserOnInit = sessionStorage.getItem("currentUser");

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

    if (this.currentUser?.roles[0] === 'Basic'){
      this.getApplicantDetails();
    }    
  }

  getApplicantDetails() {
    this._applicantService.apiVversionMembershipApplicantTabDetailsGet(environment.apiVersion, this.currentUser.userName)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response) => {
          let firstName = response.data.applicant.firstName;
          let lastName = response.data.applicant.lastName;
          let suffixName = response.data.applicant.suffixName;
          this.fullName = suffixName 
          ? firstName + " " + lastName + " " + suffixName
          : firstName + " " + lastName;
        },
        (httpError: HttpErrorResponse) => {
          this._toastr.error("", httpError.error.Message, {
            timeOut: 3000,
            positionClass: "toast-bottom-center",
            toastClass: "toast ngx-toastr",
          });
        }
      );
  }


  ngDoCheck() {
    let currentUserNow = sessionStorage.getItem("currentUser");
    let tokenNow = sessionStorage.getItem("token");
    const currentLink = this._router.url;

    if (!this.logoutTriggered && currentLink && currentLink !== '/'){
      if (this.tokenOnInit !== tokenNow){
        sessionStorage.setItem("token", this.tokenOnInit);
      }
  
      if (this.currentUserOnInit !== currentUserNow){
        sessionStorage.setItem("currentUser", this.currentUserOnInit);
      }
    }else{
      // console.log("LOGOUT TRIGGERED!");
    }

    window.onbeforeunload = (ev) => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("currentUser");
      sessionStorage.setItem("token", this.tokenOnInit);
      sessionStorage.setItem("currentUser", this.currentUserOnInit);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
